<template>
  <div>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg12>
            <v-layout row wrap justify-end>
              <v-flex class="lg4">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>Công nợ phải thu</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{summary.InDebt | currency}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex class="lg4">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>Công nợ phải trả</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{summary.OutDebt | currency}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex class="lg4">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>Tổng công nợ</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{summary.TotalDebt | currency}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
        </v-flex>
        <v-flex lg12>
          <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
          <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
              <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.UID }}</td>
                  <td>{{ item.Username }}</td>
                  <td class="text-end">{{ item.TotalAmount | currency }}</td>
                  <td class="text-end">{{ item.TotalPayment | currency }}</td>
                  <td class="text-end">{{ item.Wallet | currency }}</td>
                  <td class="text-end">{{ item.Debt | currency }}</td>
                  <td class="truncate">
                    <v-tooltip top max-width="250" color="light-blue lighten-2 black--text">
                        <template v-slot:activator="{ on }">
                            <span v-on="on">{{ item.Note }}</span>
                        </template>
                        <span>{{ item.Note }}</span>
                    </v-tooltip>
                  </td>
                  <td class="text-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                          <v-icon medium @click="gotoDetail(item.UID)" v-on="on">fa-external-link-alt</v-icon>
                      </template>
                      <span>Chi tiết công nợ</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import configs from "../../configs";
import { request_list } from "@/commons/hpod";
import Filtering from "@/components/Filtering";
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
export default {
  components: {
    "hpo-filter": Filtering
  },
  data() {
    return {
      valid: true,
      headers: [
        {
          text: "ID",
          value: "UID",
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Khách hàng",
          value: "Username",
          filterable: true,
          quickSearch: true,
          dataType: configs.DATA_TYPE["String"]
        },
        {
          text: "Tiền nạp",
          value: "TotalAmount",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Tiền hàng",
          value: "TotalPayment",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Dư ví thực tế",
          value: "Wallet",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Công nợ",
          value: "Debt",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Ghi chú",
          value: "Note",
          quickSearch: true,
          filterable: true,
          dataType: configs.DATA_TYPE["String"]
        },
        {
          text: "Chi tiết",
          value: "",
          align: "center"
        }
      ],
      pagination: {
          ...configs.PAGINATION,
          sortBy: ["TotalPayment"]
      },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      filterConditions: [],
      quickFilterConditions: [],
      viewName: "bao-cao-cong-no",
      dateRange: {
        startDate: moment
          .utc(Date.now())
          .startOf("month")
          .toISOString()
          .substr(0, 10),
        endDate: moment
          .utc(Date.now())
          .endOf("month")
          .toISOString()
          .substr(0, 10)
      },
      menuStart: false,
      menuEnd: false
    };
  },
  watch: {
    pagination: {
        handler: function (val, oldVal) {
            let filterConditions = this.$store.state.commons.filterConditions;
            this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
            this.filter_data();
        },
        deep: true
    },
  },
  computed: {
    ...mapState({
      items: state => state.report.debtReport.data,
      total_rows: state => state.report.debtReport.total,
      summary: state => state.report.debtReport.summary,
      loading: state => state.report.loading,
    }),
    computedStartDateFormatted() {
      return moment(this.dateRange.startDate).format(configs.SHORT_DATE_FORMAT);
    },
    computedEndDateFormatted() {
      return moment(this.dateRange.endDate).format(configs.SHORT_DATE_FORMAT);
    },
    adminRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
    }
  },
  methods: {
    filter_data: function() {
      var param = {
        pagination: this.pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      this.$store.dispatch("report/getDebtReport", param);
    },
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy
      let sortDesc = this.pagination.sortDesc
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    },
    gotoDetail(uid) {
      let routeData = this.$router.resolve({
        name: "bao-cao-cong-no-chi-tiet",
        params: {
          Pid: uid,
          title: `Chi tiết công nợ khách hàng (#${uid})`
        }
      });
      window.open(routeData.href, "_blank");
    }
  },
  mounted() {
    this.filter_data();
    this.$root.$emit("REQUEST_ROOT_OPTION", {});
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
  }
};
</script>